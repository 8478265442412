import logo from './logo.svg';
import './App.css';
import Masonry from 'react-masonry-component';
import { Button, Modal } from 'antd';
import React from 'react';
import "antd/dist/antd.css";

const masonryOptions = {
  transitionDuration: 0
};

const imagesLoadedOptions = { background: '.my-bg-image-el' }


const images = [
    {
        src: 'https://i.imgur.com/kUFhxJx.png',
        description: 'A worried panda about a missing mfa email, sitting in a chinese bamboo forest, chinese art style'
    },
    {
        src: 'https://i.imgur.com/QA3FI31.png',
        description: 'A magnificient guy-fawkes themed tiger, hiding behind a tree and a mask, indian art style'
    },
    {
        src: 'https://i.imgur.com/m7XHQ0v.png',
        description: 'An american bald eagle with a stole credit card in claw, background is scenic arizona desert, 8k art style, trending on artstation'
    },
    {
        src: 'https://i.imgur.com/4OOjP8l.png',
        description: 'An indonesian komodo dragon as a thief with a mask and dodgy hat, photorealistic, 8k, 35mm lens'
    },
    {
        src: 'https://i.imgur.com/SxaIRlu.png',
        description: 'A brazilian macaw with a stolen credit card in claw, photorealistic, 8k, 35mm lens'
    },
    {
        src: 'https://i.imgur.com/jQ46TEI.jpg',
        description: 'A mythological three headed Japanese dragon blooming out of the Japanese Sakura, photorealistic'
    },
    {
        src: 'https://i.imgur.com/41Dnkbv.png',
        description: 'A Japanese shiba inu with a futuristic hacker-like glasses, foreseeing incoming fraud attacks'
    },
    {
        src: 'https://i.imgur.com/j35m3NP.png',
        description: 'A Thai elephant acting suspiciously in a beautiful mountain range, digital art'
    },
    {
        src: 'https://i.imgur.com/26Y4vbC.png',
        description: 'A bear from Berlin in traditional German clothes holding a beer and a stolen credit card, photorealistic, 8k, 35mm lens'
    },
    {
        src: 'https://i.imgur.com/fdcdl3Q.png',
        description: 'A claymation french rooster hiding behind a Venetian mask, photorealistic, 8k, 35mm lens'
    },
    {
        src: 'https://i.imgur.com/YBcTRPZ.png',
        description: 'A multiple account headed British lion pouncing, stonehenge in background, 35mm lens, photorealistic, beautiful, scenic, trending on artstation'
    },
    {
        src: 'https://i.imgur.com/oSip2gu.png',
        description: 'A mysterious toy pink Thai elephant carrying bitcoin on its back, photorealistic, 8k, 35mm lens'
    },
];

class App extends React.Component {
  state = { visible: false };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = e => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };

  handleCancel = e => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };

  getNumberInRange(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  render() {
    return (
        <div id="app">
          <Masonry
              className={'my-gallery-class'} // default ''
              elementType={'ul'} // default 'div'
              options={masonryOptions} // default {}
              disableImagesLoaded={false} // default false
              updateOnEachImageLoad={false} // default false and works only if disableImagesLoaded is false
              imagesLoadedOptions={imagesLoadedOptions} // default {}
          >
            {images.map((image, index) => (
                <img width="500" className="gallery-img" onClick={async (e) => await this.setState({visible: true, src: image.src, description: image.description})} key={index} src={image.src} alt=""/>
            ))}
          </Masonry>
          <Modal
              width={1070}
              visible={this.state.visible}
              onOk={this.handleOk}
              onCancel={this.handleCancel}
          >
            <img id="modal-img" src={this.state.src} alt=""/>
            <span>{this.state.description}</span>
          </Modal>
        </div>
    );
  }
}
//
//
// function App()
//
//     const [isModalVisible, setIsModalVisible] = useState(false);
//
//     const showModal = () => {
//         setIsModalVisible(true);
//     };
//
//     const handleOk = () => {
//         setIsModalVisible(false);
//     };
//
//     const handleCancel = () => {
//         setIsModalVisible(false);
//     };
// {
//   return (
//     <div className="App">

//     </div>
//   );
// }

export default App;
